<script>
import Grid from '@/components/pages/Grid.vue';

export default {
    components: {
        Grid,
    },
    props: {
        page: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<template>
    <div
        v-if="page"
        class="page"
    >
        <Grid
            v-for="grid in page.grids"
            :key="`grid-${grid.id}`"
            :grid="grid"
        />
    </div>
</template>

<style scoped lang="scss">

</style>
