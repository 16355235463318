<template>
    <a
        v-if="url"
        :href="url"
    >
        <div class="flex">
            <div class="line" />
            <div
                :is="headingTag"
                class="text"
            >
                {{ text }}
            </div>
            <div class="line" />
        </div>
    </a>
    <div
        v-else
        class="flex"
    >
        <div class="line" />
        <div
            :is="headingTag"
            class="text"
        >
            {{ text }}
        </div>
        <div class="line" />
    </div>
</template>

<script>
export default {

    props: {
        text: {
            type: String,
            required: true,
        },
        headingTag: {
            type: String,
            default: 'h1',
        },
        url: {
            type: String,
            default: null,
        },
    },

};
</script>

<style lang="scss" scoped>
.line {
    @apply relative flex flex-grow;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
}
.text {
    @apply text-4xl font-bold tracking-wide mx-5 text-center;
}
a {
    text-decoration: none !important;
}
</style>
