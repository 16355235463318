<template>
    <div>

        <PageRowSeparator
            text="INSTAGRAM"
            class="mb-2"
        />

        <div
            class="grid gap-4"
            :class="`grid-cols-${images.length}`"
        >
            <a
                v-for="(image, index) in images"
                :key="index"
                class="overflow-hidden rounded-md hover:opacity-75 transition-fast aspect-square"
                href="https://www.instagram.com/bubblegumballoons"
                target="_blank"
            >
                <img
                    v-lazy="image"
                    :alt="`Instagram Feed Image`"
                    class="object-cover w-full h-full"
                >
            </a>
        </div>

        <div class="text-center mt-2">
            <a
                class="font-bold"
                href="https://www.instagram.com/bubblegumballoons"
                target="_blank"
            >#bubblegumballoons</a>
            Follow the Bubblegum Girls for inspiration, latest products and top tips!
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageRowSeparator from '@/components/PageRowSeparator';

export default {

    components: {
        PageRowSeparator,
    },

    computed: {
        images() {
            return this.getByKey('instagram_images') || [];
        },
        ...mapGetters('settings', [
            'getByKey',
        ]),
    },

};
</script>

<style lang="scss" scoped>
.aspect-square {
    aspect-ratio: 1;
}
</style>
