<template>
    <Page
        v-if="page"
        class="page"
        :page="page"
    />
</template>

<script>
import PageInstagramFeed from '~/components/PageInstagramFeed';
import Page from '@/components/pages/Page.vue';

export default {

    components: {
        Page,
        PageInstagramFeed,
    },

    async asyncData({ store }) {
        return {
            page: await store.dispatch('content/getHomePage'),
        };
    },

    head() {
        return {
            title: this.page?.meta_title || this.page?.title,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.page?.meta_description,
                },
                {
                    hid: 'og:title',
                    name: 'og:title',
                    content: this.page?.meta_title,
                },
            ],
        };
    },

};
</script>

<style>
</style>
